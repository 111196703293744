import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,

} from "@remix-run/react"
import { captureRemixErrorBoundaryError } from "@sentry/remix"

import "./tailwind.css"
import { StructuredData } from "@/components/structured-data"
import { Organization } from "@/components/structured-data/organization"

import { Button } from "./components/button"
import { cn } from "./lib/utils"

export function loader() {
  return {
    DISABLE_GTM: Boolean(process.env.DISABLE_GTM)
  }
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useLoaderData() as { DISABLE_GTM: boolean }
  const { pathname } = useLocation()
  const isExpeditionsPath = pathname.startsWith('/expeditions')
  return (
    <html lang="pt-BR">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge,chrome=1" />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:site_name" content="Bubble Geek" />
        <meta property="twitter:site" content="@bubblegeek" />
        <meta property="twitter:creator" content="@bubblegeek" />
        <meta property="twitter:domain" content="bubblegeek.com.br" />
        <meta name="robots" content="max-image-preview:large" />
        <meta property="fb:article_style" content="default" />
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="64x64" href="/icons/favicon-64x64.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/icons/favicon-72x72.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/icons/favicon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icons/favicon-152x152.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/icons/favicon-192x192.png" />
        <meta name="msapplication-TileImage" content="/icons/favicon-144x144.png" />
        <meta name="msapplication-TileColor" content="#4338CA" />
        <link rel="preload" href="/logo.webp" as="image" type="image/webp" />
        <link rel="preload" href="/fonts/IBMPlexSans/IBMPlexSans-Text.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/IBMPlexSans/IBMPlexSans-Bold.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <style dangerouslySetInnerHTML={{
          __html: `        
          @font-face {
                font-family: 'IBM Plex Sans';
                src: url('/fonts/IBMPlexSans/IBMPlexSans-Text.eot');
                src: url('/fonts/IBMPlexSans/IBMPlexSans-Text.eot?#iefix') format('embedded-opentype'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-Text.woff2') format('woff2'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-Text.woff') format('woff'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-Text.ttf') format('truetype');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
            }

            @font-face {
                font-family: 'IBM Plex Sans';
                src: url('/fonts/IBMPlexSans/IBMPlexSans-TextItalic.eot');
                src: url('/fonts/IBMPlexSans/IBMPlexSans-TextItalic.eot?#iefix') format('embedded-opentype'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-TextItalic.woff2') format('woff2'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-TextItalic.woff') format('woff'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-TextItalic.ttf') format('truetype');
                font-weight: normal;
                font-style: italic;
                font-display: swap;
            }

            @font-face {
                font-family: 'IBM Plex Sans';
                src: url('/fonts/IBMPlexSans/IBMPlexSans-Medium.eot');
                src: url('/fonts/IBMPlexSans/IBMPlexSans-Medium.eot?#iefix') format('embedded-opentype'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-Medium.woff2') format('woff2'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-Medium.woff') format('woff'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-Medium.ttf') format('truetype');
                font-weight: 500;
                font-style: normal;
                font-display: swap;
            }

            @font-face {
                font-family: 'IBM Plex Sans';
                src: url('/fonts/IBMPlexSans/IBMPlexSans-Bold.eot');
                src: url('/fonts/IBMPlexSans/IBMPlexSans-Bold.eot?#iefix') format('embedded-opentype'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-Bold.woff2') format('woff2'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-Bold.woff') format('woff'),
                    url('/fonts/IBMPlexSans/IBMPlexSans-Bold.ttf') format('truetype');
                font-weight: 700;
                font-style: normal;
                font-display: swap;
            }
        `}}>
        </style>
        <Meta />
        <Links />
        <StructuredData data={{
          "@context": "https://schema.org",
          ...Organization
        }} />
        {!data?.DISABLE_GTM && (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-L1Z8D1ZDSG"></script>
            <script dangerouslySetInnerHTML={{
              __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-L1Z8D1ZDSG');
        ` }}>
            </script>
          </>
        )}
      </head>
      <body className={cn(isExpeditionsPath && "bg-[#1D2F3D] py-12")}>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()
  const location = useLocation()

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <div className="justify-center items-center flex flex-col gap-4 p-4 h-screen bg-[#FFFFF4]">
          <Link to="/">
            <img src="/logo.webp" alt="Bubble Geek" title="Bubble Geek" className="h-7 w-auto" width="185" height="28" />
          </Link>
          <img src="https://bubblegeek.com.br/media/404-1.webp" alt="Página não encontrada" className="max-w-[500px] w-11/12 h-auto" />
          <h1 className="text-2xl font-bold">Página não encontrada</h1>
          <p className="text-center">O endereço abaixo não existe no bubblegeek.com.br <strong className="block font-medium">https://bubblegeek.com.br{location.pathname}</strong></p>
          <Button asChild>
            <Link to="/">
              Ir para a página inicial
            </Link>
          </Button>
        </div>
      )
    }
  }

  captureRemixErrorBoundaryError(error)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '16px', padding: '16px', height: '100vh', backgroundColor: '#FFFFF4' }}>
      <Link to="/">
        <img src="/logo.webp" alt="Bubble Geek" title="Bubble Geek" style={{ height: '28px', width: 'auto' }} width="185" height="28" />
      </Link>
      <img src="https://bubblegeek.com.br/error500.webp" alt="Ops! Algo deu errado" style={{ maxWidth: '500px', width: '91.6667%', height: 'auto' }} />
      <h1 style={{ margin: 0, fontWeight: 'bold', fontSize: '1.3rem', marginTop: '1rem' }}>Ops! Algo deu errado!</h1>
      <p style={{ margin: 0 }}>Relaxa, a culpa não é sua, é nossa. Tenta atualizar o navegador ou volta daqui a pouco.</p>
      <Button style={{ padding: '12px 16px', backgroundColor: '#111827', color: '#FFFFFF', borderRadius: '6px', textDecoration: 'none', cursor: 'pointer', border: 'none' }}>
        <Link to="/" style={{ color: '#FFFFFF', fontWeight: 'bold', textDecoration: 'none' }}>
          Ir para a página inicial
        </Link>
      </Button>
    </div>
  )
}

export default function App() {
  return <Outlet />
}
